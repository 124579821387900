<template>
  <ToolsFormEccentricity />
</template>

<script>
import ToolsFormEccentricity from '../../components/tools/ToolsFormEccentricity.vue';

export default {
  components: {
    ToolsFormEccentricity,
  },
};
</script>

<style scoped lang="scss">

</style>
